.section.projects {
  margin-top: -15rem;

  .container {
    max-width: 1334px;
    border-radius: 12px;
    background-color: white;
    margin: 0 30px;

    .box {
      .title-projects {
        font-size: 2rem;
        color: black;
        padding-top: 30px;
      }

      .content {
        .columns {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;

          .column {
            width: 300px;
            margin: 0 10px;
            &:not(:last-child) {
              border-right: 1px solid #6e07f3;
            }

            .job-description {
              height: 90px;
            }

            .achivement-title {
              color: #6e07f3;
              font-weight: 500;
            }

            .achivement-lists {
              padding-left: 0;

              li {
                text-align: left;
                padding: 5px 0;
              }
            }
            .title {
              color: black;

              .companies {
                font-weight: normal;
                display: block;
              }
            }

            span {
              font-weight: 500;
              color: #6e07f3;
            }
          }
        }
      }
    }
  }
}

p.companies-date {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .section.projects .container .box .content .columns .column:not(:last-child) {
    border-right: none;
  }

  .section.projects
    .container
    .box
    .content
    .columns
    .column
    .achivement-lists {
    padding-left: inherit;
  }

  .box {
    .title-projects {
      font-size: 2rem;
      color: black;
      padding-top: 30px;
    }

    .content {
      .columns {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
    }
  }
}
