.startup-grid .columns {
  border-radius: 12px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .column {
    margin: 10px;
    padding: 30px;

    .box {
      height: 450px;
      width: 450px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 20px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        transform: translate(20px, 10px);
        transition: 0.8s;
      }

      .flip-card-front {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .project-img-container {
        width: 200px;
        height: 120px;

        .my-project-images {
          width: 100%;
          border-radius: 10px;
        }
      }
      span {
        color: #6e07f3;
      }

      p:last-child {
        font-weight: 500;
      }
    }

    .check-me-out-btn {
      background-color: transparent;
      border-color: #6e07f3;
      color: #6e07f3;
      border-width: 1px;
      font-size: 15px;
      padding: 1rem;
      margin-top: 10px;
      border-radius: 9999px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .startup-grid .columns {
    border-radius: 12px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .column {
      margin: 10px;
      padding: 30px;

      .box {
        width: unset;
        height: fit-content;
      }
    }
  }
}

section.section.projects.has-text-centered {
  display: flex;
  justify-content: center;
}
