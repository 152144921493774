.body-introduction {
  margin-top: -10px;
}
.columns {
  display: flex;
  justify-content: center;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;

  .column {
    .intro-title {
      color: white;
    }
    .intro-subtitle {
      color: white;
      font-weight: normal;
    }
  }
}
.section.is-medium {
  padding: 7rem 0 16rem;
  background-color: #6e07f3;
}

.column.is-three-fifths {
  flex: none;
  width: 60%;
}
