.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-around;
  padding: 20px 0;

  .navbar-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 85px;
    height: 55px;
    font-size: 18px;
    padding: 15px;
  }
}

.navbar-item {
  button {
    background-color: transparent;
    border-color: #6e07f3;
    color: #6e07f3;
    border-width: 2px;
    font-size: 18px;
    padding: 1.2rem;
    border-radius: 9999px;
    margin: 0 10px;
    cursor: pointer;
  }
}

.burger-menu {
  width: 2rem;
  height: 2rem;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

@media (max-width: 767px) {
  .burger-menu {
    display: flex;
  }
  .navbar-item {
    display: none;

    button {
      padding: 0.8rem;
    }

    &.display-nav-items {
      display: flex;
    }
  }

  .logo {
    display: none;
  }

  .navbar-end {
    display: flex;
    align-items: center;
  }
}
