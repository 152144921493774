.projects-section {
  background-color: #6e07f3;
  color: white;
  padding: 7rem 0 16rem;

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-weight: normal;
  }
}
